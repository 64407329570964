.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 1s linear;
    }
}

.test {
    border: 3px solid green;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* login styles to get rid of auto-fill styles: may or may not work */
/* Only for Safari  */

::i-block-chrome,
input:-internal-autofill-selected {
    background-color: none !important;
}

/* Only for Safari  */

::i-block-chrome,
.css-pop9t0:-webkit-autofill {
    box-shadow: none !important;
}